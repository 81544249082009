.steps-list .step {
  @apply pl-0  relative py-3 lg:flex-wrap;
}
.steps-list .MuiButtonBase-root.step {
  @apply !p-0 min-h-[auto];
}
.steps-list .MuiAccordionDetails-root {
  @apply !p-0;
}
.steps-list .MuiAccordionSummary-content {
  @apply !m-0;
}
.MuiPaper-root.steps-list {
  box-shadow: unset !important;
}
.table tr td,
.table tr th{
  @apply md:p-2; 
}
.table{
  @apply md:p-2 w-max 
}