  .student_dashboard_by_card {
    @apply p-4 rounded-md border border-slate-200 hover:shadow-lg transition-all;
  }
  .student_dashboard_by_card .icon {
    @apply w-24 h-24 rounded-full flex justify-center items-center mx-auto;
  }

  .student_dashboard_by_card .image {
    @apply w-40 h-24 flex justify-center items-center mx-auto;
  }
  
  .student_dashboard_by_card .number {
    @apply text-3xl mt-3 font-medium md:text-xl;
  }
  .student_dashboard_by_card .title {
    @apply text-3xl mt-2 font-semibold md:text-2xl;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    @apply !border-primary;
  }
  