@tailwind base;
@tailwind components;
@tailwind utilities;
@import '/node_modules/ckeditor5/dist/ckeditor5-content.css';

:root {
  --theme-color: #2d3e48;
  --font-color: #000000;
  --local-theme-color: #2d3e48;
  --local-font-color: #000000;
  --menu-color: #fea087;
}

body {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Outfit', sans-serif;
  color: #425b71;
}
@layer base {
  .form-input {
    @apply block w-full py-3 px-4 border border-gray-300 rounded-md focus:outline-0 focus:border-primary;
  }
  .form-label {
    @apply text-left block mb-1;
  }

  .btn-primary {
    @apply py-2 px-7 border border-primary rounded-md hover:bg-primary hover:text-white md:px-4;
  }
  .spinner {
    @apply block w-5 h-5 rounded-full border-2 border-gray-200 border-l-gray-950 animate-[spin_0.4s_linear_0s_infinite];
  }

  .btn-secondary {
    @apply py-2 px-7 border border-secondary text-secondary rounded-md hover:bg-secondary hover:text-white;
  }
  .btn-danger {
    @apply py-2 px-7 rounded-md border border-red-600 text-red-600 hover:bg-red-600 hover:text-white;
  }
  .badge.badge-primary {
    @apply bg-primary text-base py-1 px-3 text-white rounded-full;
  }
}

.html-content.p-revert p {
  all: revert;
}

/* width */
::-webkit-scrollbar {
  @apply w-2 h-1;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-slate-100 rounded-lg;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply visa-portal-theme-bg rounded-lg;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply visa-portal-theme-bg bg-opacity-50;
}

.disabled {
  opacity: 0.5; /* Make the button appear faded */
  cursor: not-allowed;
}
body:has(.model-open),
body:has(#email-template-container) {
  overflow: hidden;
}

body:has(#institute-letter-view-edit) #basic-setting-content {
  @apply hidden;
}

.badge-primary {
  @apply px-3 py-1 bg-slate-100 border border-slate-200 rounded-full font-semibold text-sm md:text-xs text-black cursor-pointer hover:bg-primary hover:text-white;
}
.h_screen {
  @apply h-[calc(100%-77px)];
}

.bg-loader {
  background: url('./assets/images/loader.gif') center no-repeat;
}

#email-template-container img {
  @apply min-h-[200px] min-w-[200px];
  background: url('./assets/images/loader.gif') left top no-repeat;
}
.MuiBox-root.css-175jobk {
  @apply md:border-0 md:!p-4 md:w-11/12;
}

.emergency-filter .MuiFormControl-root.MuiTextField-root {
  @apply md:min-w-36 md:w-36;
}
.view-student .MuiInputBase-root.MuiOutlinedInput-root {
  @apply w-full;
}
.MuiInputBase-root.MuiOutlinedInput-root {
  @apply md:w-full;
}
.sigCanvas {
  cursor: url('./assets/images/pen.cur'), pointer;
}

@keyframes scaleAndReset {
  0% {
    transform: translateX(0%) scale(1); /* Start the animation from scale 0 */
  }
  50% {
    font-weight: bolder;
    transform: translateX(15%) scale(1.3); /* Animate to scale 1 */
  }
  100% {
    transform: translateX(0%) scale(1); /* Start the animation from scale 0 */
  }
}

.animate-once {
  animation-name: scaleAndReset;
  animation-duration: 0.5s; /* Control the speed of the animation */
  animation-fill-mode: backwards; /* Keeps the property values from the last keyframe at the end of the animation */
  animation-timing-function: ease-out; /* Makes the animation start fast, then slow down */
  animation-iteration-count: 1; /* Run the animation only once */
}

.form-input[disabled] {
  @apply !bg-gray-300 !cursor-not-allowed;
}

.custom-scrollbar::-webkit-scrollbar {
  @apply h-3;
}

.dashboard-icon-home-college .MuiInputBase-root {
  @apply max-h-[130px] overflow-auto static;
}
.tab-list .MuiTabScrollButton-root.Mui-disabled.MuiTabs-scrollButtons {
  @apply hidden;
}

.ck-editor__editable {
  min-height: 400px;
}
.note-student .ck-editor__editable {
  min-height: 52vh;
}
.note-advisor .ck-editor__editable {
  min-height: 370px;
}
.ck-editor__editable a{
  color: -webkit-link !important;
}

.ck-editor__editable a:-webkit-any-link:read-write{
  cursor: text !important;
}

.ck-editor__editable a:-webkit-any-link{
  color: -webkit-link !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}
.ck-editor__editable .table.ck-widget {
  @apply ml-0;
}
.ck .ck-sticky-panel .ck-sticky-panel__content_sticky {
  @apply !static;
}
