table,
table td,
table th {
  @apply border-collapse border border-gray-200 py-2 px-4;
}
table tbody tr:nth-child(even) {
  @apply bg-gray-100;
}
table tbody tr:hover {
  @apply bg-gray-50;
}

.role-dropdown-tag-container-parent > div {
  @apply flex flex-wrap gap-y-2 gap-x-3;
}

#global-student-views-table tbody td {
  @apply h-[102px];
}
